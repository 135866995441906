<template>
  <div class="panel">
    <el-form
      label-position="top"
      size="mini"
      :model="createPartnerForm"
      :rules="rules"
      ref="createPartnerForm"
    >
      <el-row>
        <el-col :span="12" :offset="6">
          <el-form-item
            label="Нэвтрэх нэр"
            prop="user_name"
            v-if="!mArrayIncludes(role, ['finance', 'other'])"
          >
            <el-input
              v-model="createPartnerForm.user_name"
              placeholder="Системд нэвтрэхэд ашиглагдах нэр"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Харилцагч нэвтрэх нууц үг"
            prop="raw_password"
            v-if="!mArrayIncludes(role, ['finance', 'other'])"
          >
            <el-input
              v-model="createPartnerForm.raw_password"
              placeholder="Системд нэвтрэх нууц үг"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Харилцагч хаах/нээх нууц үг"
            prop="password1"
            v-if="!mArrayIncludes(role, ['finance', 'other'])"
          >
            <el-input
              v-model="createPartnerForm.password1"
              type="text"
              placeholder="Системд нэвтрэх нууц үг дахин оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Зассан шалтгаан бичих">
            <el-input
              type="textarea"
              rows="2"
              v-model="createPartnerForm.desc"
              placeholder="Зассан шалтгаан оруулна уу!!!"
            ></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="8">
              <!-- <router-link to="/main"> -->
              <el-button
                type="success"
                size="medium"
                class="full-width"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
                @click="Add()"
                >Дуусгах</el-button
              >
              <!-- </router-link> -->
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { arrayIncludes } from "../helpers/helper";
import { getGroups } from "../utils/auth";
export default {
  created() {
    this.openFullScreenLoader(true);
    this.getOutletById(this.$route.query._id);
    this.getoutletdevicebyoutletname(this.$route.query._id);
    getGroups().then(data => {
      this.role = data;
      if (this.role.includes("address_editor")) {
        this.$router.push({
          path: "/list-address"
        });
      }
    });
  },
  data() {
    return {
      loading: null,
      createPartnerForm: {
        outlet_name: "",
        user_name: "",
        password: null,
        password1: null,
        raw_password: null,
        desc: null
      },
      outletForm: {},
      rules: {
        user_name: [
          {
            required: true,
            message: "Нэвтрэх нэр заавал оруулна уу",
            trigger: "blur"
          },
          {
            min: 4,
            message: "нэвтрэх нэр шаардлага хангахгүй байна",
            trigger: "blur"
          }
        ],
        raw_password: [
          {
            required: true,
            message: "Шинэ нууц үгээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 4,
            message: "Нууц үгний урт шаардлага хангахгүй байна",
            trigger: "blur"
          }
        ],
        password1: [
          {
            required: true,
            message: "Хаах нээх нууц үг заавал оруулна уу",
            trigger: "blur"
          },
          {
            min: 4,
            message: "Нууц үгний урт шаардлага хангахгүй байна",
            trigger: "blur"
          }
        ],
        desc: [
          {
            required: true,
            message: "Зассан шалтгаан заавал оруулна уу",
            trigger: "blur"
          }
        ]
      },
      role: []
    };
  },
  methods: {
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн мэдээлэл татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    getOutletById(id) {
      const payload = { includeFields: [] };
      service.getOutletById(id, payload).then(outletResponses => {
        this.createPartnerForm.outlet_name =
          outletResponses.data.data.outlet_name;
        this.createPartnerForm.outlet_id = this.$route.query._id;
      });
      this.openFullScreenLoader(false);
    },
    getoutletdevicebyoutletname(id) {
      service.kitchenLogin(id).then(outletResponse => {
        this.createPartnerForm.outlet_name = outletResponse.data.data[0].name;
        this.createPartnerForm.user_name =
          outletResponse.data.data[0].login_name;
        this.createPartnerForm.password = outletResponse.data.data[0].password;
        this.createPartnerForm.password1 =
          outletResponse.data.data[0].password1;
        this.createPartnerForm.id = outletResponse.data.data[0].id;
        this.createPartnerForm.raw_password =
          outletResponse.data.data[0].raw_password;
      });
    },
    Add() {
      this.$refs.createPartnerForm.validate(async valid => {
        if (valid) {
          if (!this.createPartnerForm.id) {
            const payload = {
              outlet_name: this.createPartnerForm.outlet_name,
              login_name: this.createPartnerForm.user_name,
              user_name: this.createPartnerForm.user_name,
              password: this.createPartnerForm.raw_password,
              outlet_id: this.$route.query._id,
              contract_is_paid: this.createPartnerForm.raw_contract_is_paid,
              contract_expire_at: this.createPartnerForm.raw_contract_expire_at,
              contract_discount_percent: this.createPartnerForm
                .raw_contract_discount_percent,
              desc: this.createPartnerForm.desc
            };
            await service
              .createNewPartnerLogin(this.$route.query._id, payload)
              .then(outletResponse => {
                if (outletResponse.data.data.message === "Амжилттай") {
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай бүртгэгдлээ",
                    type: "success"
                  });
                  this.$router.push({
                    path: "/main"
                  });
                } else {
                  this.$notify({
                    title: "Амжилтгүй",
                    message: outletResponse.data.data.message,
                    type: "warning"
                  });
                }
              });
          } else {
            this.createPartnerForm.outlet_id = this.$route.query._id;
            if (
              this.createPartnerForm.desc === null ||
              this.createPartnerForm.desc === ""
            ) {
              return this.$notify.error({
                title: "Error",
                message: "Зассан шалтгаан бичээгүй байна !!!"
              });
            } else {
              const payloadBody = {
                outlet_name: this.createPartnerForm.outlet_name,
                password: this.createPartnerForm.password,
                password1: this.createPartnerForm.password1,
                raw_password: this.createPartnerForm.raw_password,
                desc: this.createPartnerForm.desc,
                id: this.createPartnerForm.id,
                login_name: this.createPartnerForm.user_name,
                user_name: this.createPartnerForm.user_name
              };
              await service
                .updatePartnerLogin(this.$route.query._id, payloadBody)
                .then(outletResponse => {
                  if (outletResponse.data.status === "success") {
                    this.$notify({
                      title: "Амжилттай",
                      message: "Бүртгэлийн мэдээлэл засагдлаа",
                      type: "success"
                    });
                    this.$router.push({
                      path: "/main"
                    });
                  } else {
                    this.$notify({
                      title: "Амжилтгүй",
                      message:
                        "Бүртгэлийн мэдээлэл засахад алдаа гарлаа " +
                        " " +
                        outletResponse.error.message,
                      type: "warning"
                    });
                  }
                });
            }
          }
        } else {
          return false;
        }
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    }
  }
};
</script>
